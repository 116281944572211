<template>
  <div class="dropzone-container">
    <div class="files" v-show="!!files.length">
      <div
        class="file-row d-flex justify-content-between align-items-center p-3 rounded my-2"
        v-for="file in files"
        :key="`file-${file.upload.uuid}`"
      >
        <p class="m-0">{{ file.name }}</p>
        <button class="btn btn-danger px-3 py-2" @click="removeFile(file)">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
    <vue-dropzone
      id="dropzone"
      v-show="!files.length"
      :ref="ref"
      :options="dropzoneOptions"
      :multipleUpload="false"
      :useCustomSlot="true"
      @vdropzone-files-added="filesChange"
    >
      <slot />
    </vue-dropzone>
  </div>
</template>
<script>
import vueDropzone from 'vue2-dropzone'

export default {
  components: {
    vueDropzone
  },
  data() {
    return {
      ref: `dropzone-${new Date().getTime()}`,
      dropzoneOptions: {},
      files: []
    }
  },
  props: {
    value: {
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  created() {
    this.dropzoneOptions = {
      ...this.options,
      url: process.env.VUE_APP_NEW_API,
      autoProcessQueue: false,
      init: function() {}
    }
  },
  methods: {
    getRef() {
      return this.$refs[this.ref]
    },
    filesChange(files) {
      console.log(files)

      const someFilesNotAccepted = !Array.from(files).some(
        file => file.accepted
      )
      if (someFilesNotAccepted) {
        console.log('some not accepted')
        this.toast(
          `some files are not accepted please only upload files that are accepted in this list (${this.dropzoneOptions.acceptedFiles})`,
          { type: 'error' }
        )
        this.reset()
        return
      }

      if (files.length > this.dropzoneOptions.maxFiles) {
        console.log('max files')
        this.toast(
          `you can't upload more than ${this.dropzoneOptions.maxFiles} file`,
          { type: 'error' }
        )
        this.reset()
        return
      }
      this.files = files
      this.$emit('input', files)
    },
    removeFile(file) {
      this.getRef().removeFile(file)
      this.files = this.getRef().dropzone.files
      this.$emit('input', this.files)
    },
    reset() {
      setTimeout(() => {
        this.getRef().removeAllFiles()
        this.files = []
        this.$emit('input', [])
      }, 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.file-row {
  background-color: #eee;
  gap: 0.5rem;
}
</style>
